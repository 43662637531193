/**
 * Handle phone fields using `intl-tel-input` component.
 */

(function (root, factory) {
	if ( typeof define === 'function' && define.amd ) {
		define([], factory(root));
	} else if ( typeof exports === 'object' ) {
		module.exports = factory(root);
	} else {
		root.IntlTelInputHandler = factory(root);
	}
})(typeof global !== 'undefined' ? global : this.window || this.global, function (root) {

	'use strict';

	var $ = jQuery;
	var _hasJQuery = ( $ != null );

	var _hasInitialized = false;
	var _publicMethods = {};
	var _settings = {
		bodyClass:                'has-fc-intl-tel-input--activated',

		phoneFieldSelector:       '[data-phone-field], input.js-phone-field, .js-phone-field input, #billing_phone, #shipping_phone, #phone',
		formRowSelector:          '.form-row',
		intlTelContainerSelector: '.iti',
		flagContainerSelector:    '.iti__flag-container',
		hiddenInputSuffix:        '_full',

		enablePhoneValidation:    false,
		typePhoneSelector:        '.validate-phone',
		validationMessages: {
			invalid_generic:      'This is not a valid phone number.',
			invalid_country:      'This is not a valid phone number for this country.',
			invalid_country_code: 'This is not a valid phone number: invalid country code.',
			too_short:            'This is not a valid phone number: too short.',
			too_long:             'This is not a valid phone number: too long.',
		},
	};
	var _defaultFieldSettings = {
		separateDialCode:     true,
		autoPlaceholder:      'off',
	};




	/**
	 * METHODS
	 */



	/**
	 * Check if the field is a phone field.
	 * @param  {Field}    field            Field for validation.
	 * @param  {Element}  formRow          Form row element.
	 * @param  {String}   validationEvent  Event that triggered the validation.
	 * @return {Boolean}                   Whether the field is a Phone field.
	 */
	var isPhoneField = function( field, formRow, validationEvent ) {
		if ( ! formRow.matches( _settings.typePhoneSelector ) ) { return false; }
		return true;
	};

	/**
	 * Validate phone field value.
	 * @param  {Field}    field            Field for validation.
	 * @param  {Element}  formRow          Form row element.
	 * @param  {String}   validationEvent  Event that triggered the validation.
	 * @return {Boolean}                   Whether the field value is a valid phone field.
	 */
	var validatePhoneField = function( field, formRow, validationEvent ) {
		// Bail as `valid` if field does not have a value
		// Required phone fields are validated by `CheckoutValidation` required validation type.
		if ( ! CheckoutValidation.hasValue( field ) ) { return { valid: true }; }

		// Get phone field instance
		var phoneField = intlTelInputGlobals.getInstance( field );

		// Bail if phone field is invalid
		if ( phoneField && ! phoneField.isValidNumber() ) {
			// Define default error message
			var errorMessage = _settings.validationMessages.invalid_generic;

			// Try get a more specific error message
			var errorCode = phoneField.getValidationError();
			switch ( errorCode ) {
				case intlTelInputUtils.validationError.TOO_SHORT:
					errorMessage = _settings.validationMessages.too_short;
					break;
				case intlTelInputUtils.validationError.TOO_LONG:
					errorMessage = _settings.validationMessages.too_long;
					break;
				case intlTelInputUtils.validationError.INVALID_COUNTRY_CODE:
					errorMessage = _settings.validationMessages.invalid_country_code;
					break;
				case intlTelInputUtils.validationError.IS_POSSIBLE:
				case intlTelInputUtils.validationError.IS_POSSIBLE_LOCAL_ONLY:
					errorMessage = _settings.validationMessages.invalid_country;
					break;
			}

			return { valid: false, message: errorMessage };
		}

		// Field is valid
		return { valid: true };
	};

	/**
	 * Register validation types.
	 */
	var registerValidationTypes = function() {
		if ( _settings.enablePhoneValidation ) {
			CheckoutValidation.registerValidationType( 'phone', 'phone', isPhoneField, validatePhoneField );
		}
	}



	/**
	 * Populate hidden field with full phone number
	 */
	var populateHiddenField = function( field ) {
		var phoneField = intlTelInputGlobals.getInstance( field );

		// Bail if hidden field is not available
		if ( ! phoneField || ! phoneField.hiddenInput ) return;

		phoneField.hiddenInput.value = phoneField.getNumber() || field.value;
	};

	/**
	 * Format phone field.
	 */
	var formatPhoneField = function( field ) {
		// Maybe format number
		if ( window.intlTelInputGlobals ) {
			var phoneField = intlTelInputGlobals.getInstance( field );
			phoneField.setNumber( phoneField.getNumber() );
		}
	}

	/**
	 * Initialize all phone fields.
	 */
	var initializeAllFields = function() {
		var phoneFields = document.querySelectorAll( _settings.phoneFieldSelector );
		for ( var i = 0; i < phoneFields.length; i++ ) {
			_publicMethods.initializeField( phoneFields[i] );
		}
	};

	/**
	 * Initialize all phone fields.
	 */
	var populateAllHiddenFields = function() {
		var phoneFields = document.querySelectorAll( _settings.phoneFieldSelector );
		for ( var i = 0; i < phoneFields.length; i++ ) {
			populateHiddenField( phoneFields[i] );
		}
	};



	/**
	 * Handle blur event
	 */
	var handleBlur = function( e ) {
		// Bail if target element does not support `matches` method
		if ( ! e.target.matches ) { return; }

		// Bail if target field is not a phone field
		if ( ! e.target.matches( _settings.phoneFieldSelector ) ) { return; }

		var field = e.target;
		populateHiddenField( field );
		formatPhoneField( field );
	};




	/**
	 * Handle change event
	 */
	var handleChange = function( e ) {
		// Bail if target field is not a phone field
		if ( ! e.target.matches( _settings.phoneFieldSelector ) ) { return; }

		var field = e.target;
		populateHiddenField( field );
		formatPhoneField( field );
	};



	/**
	 * Initialize a phone field.
	 */
	_publicMethods.initializeField = function( field ) {
		var fieldSettings = _defaultFieldSettings;

		// Set hidden field name
		fieldSettings.hiddenInput = field.getAttribute( 'name' ) + _settings.hiddenInputSuffix;

		// Intialize field
		window.intlTelInput( field, fieldSettings );

		// Await for field to be mounted
		requestAnimationFrame( function() {
			// set initial value
			populateHiddenField( field );
		} );
	}



	/**
	 * Initialize intl phone number inputs
	 */
	_publicMethods.init = function( options ) {
		if ( _hasInitialized ) return;

		// Bail if intl-tel-input library has not been loaded
		if ( ! window.intlTelInput ) { return; }

		// Merge settings
		_settings = FCUtils.extendObject( _settings, options );
		_defaultFieldSettings = options && options.fieldSettings ? FCUtils.extendObject( _defaultFieldSettings, options.fieldSettings ) : _defaultFieldSettings;

		// Load utils
		if ( _settings.utilsScript && window.intlTelInputGlobals ) {
			intlTelInputGlobals.loadUtils( _settings.utilsScript ).then( initializeAllFields );
		}

		// Set event listeners
		document.addEventListener( 'blur', handleBlur, true );
		document.addEventListener( 'change', handleChange, true );
		document.addEventListener( 'countrychange', handleChange, true );

		// Set jQuery event listeners
		if ( _hasJQuery ) {
			$( document.body ).on( 'update_checkout', populateAllHiddenFields );
			$( document.body ).on( 'updated_checkout', initializeAllFields );
		}

		// Integrate with `CheckoutValidation` if available
		if ( window.CheckoutValidation ) {
			// Register validation types
			registerValidationTypes();
		}

		// Add init class
		document.body.classList.add( _settings.bodyClass );

		_hasInitialized = true;
	};



	//
	// Public APIs
	//
	return _publicMethods;

});
